/* import __COLOCATED_TEMPLATE__ from './accounts-table.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { next } from '@ember/runloop';

export default Component.extend({
  // allow the table to render in a loading state for the first paint of the
  // page, then disable the loading state during the next runloop to render
  // the table contents
  renderTableLoadingState: true,
  didRender() {
    this._super(...arguments);
    next(this, () => {
      if (this.isDestroying) {
        return;
      }
      this.set('renderTableLoadingState', false);
    });
  },
});
