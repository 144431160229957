/* import __COLOCATED_TEMPLATE__ from './account-admin-avatar-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { later } from '@ember/runloop';
import { action } from '@ember/object';
import $ from 'jquery';
import { gt, and } from '@ember/object/computed';
import Component from '@ember/component';
import ImageUploadComponent from 'embercom/components/mixins/image-upload-component';

export default Component.extend(ImageUploadComponent, {
  fileIsLargerThanOneMB: gt('file.size', 1000000),
  fileIsTooLarge: and('fileIsLargerThanOneMB', 'hasFile'),

  removeFile: action(function () {
    this.set('file', null);
    this.set('hasFile', false);
    this.set('previewImageUrl', null);
  }),

  showFilePicker: action(function () {
    $('input:file', this.element).click();
  }),

  saveAdminAvatar: action(function () {
    this.set('isSaving', true);
    let admin = this.admin;
    let file = this.file;

    admin.createAvatar(file).then(
      () => {
        later(() => {
          this.setProperties({
            isSaving: false,
            hasSaved: true,
          });

          this.finishActiveCard();
        }, 500);
      },
      () => {
        this.set('hasErrored', true);
      },
    );
  }),

  removeAdminAvatar: action(function () {
    this.admin.destroyAvatar();
  }),
});
