/* import __COLOCATED_TEMPLATE__ from './inbox-preview.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default Component.extend({
  store: service(),
  appService: service(),
  app: readOnly('appService.app'),
  avatarUrl: readOnly('app.currentAdmin.avatarUrl'),

  init() {
    this._super(...arguments);

    let participant = this.store.createRecord('participant', {
      id: '0',
      avatar: {
        initials: 'SC',
        color: 'f26c73',
        image_urls: {
          normal: assetUrl('/assets/images/user-avatar.png'),
        },
      },
      isActive: true,
      user_id: 1,
      isUserRole: true,
      role: 'user_role',
      firstName: 'Stefanie',
      display_as: 'Stefanie Crisman',
    });

    let parts = [
      this.createUserCommentPart('1', participant, [
        { type: 'paragraph', text: 'Hey, is it possible to export a daily usage report?' },
      ]),
      this.createAdminCommentPart('2', [
        {
          type: 'paragraph',
          text: 'Hey Stefanie! Yep, you can do that with the Export button on the top of your dashboard.',
        },
      ]),
      this.createAdminNotePart('3', [
        { type: 'paragraph', text: 'Follow up with customer if no reply' },
      ]),
    ];

    let conversation = this.createPreviewConversation(participant, parts);

    this.set('conversation', conversation);
  },

  createUserCommentPart(id, participant, blocks) {
    let partOptions = {
      id: `user-message-123-${id}`,
      participant_id: participant.id,
      created_at: new Date().toJSON(),
      type: 'message',
      channel_type: 'Email',
      blocks,
    };

    let part = this._pushAndPeekPart(partOptions);
    part.set('shouldDisableAvatarLinkAndTooltip', true);
    return part;
  },

  createAdminCommentPart(id, blocks) {
    let admin = this.get('app.currentAdmin');
    let partOptions = {
      id: `comment-123-${id}`,
      admin_id: admin.id,
      assignee_id: admin.id,
      created_at: new Date().toJSON(),
      type: 'comment',
      sub_type: 'assignment',
      blocks,
    };

    let part = this._pushAndPeekPart(partOptions);

    part.setProperties({
      isUserPart: false,
      shouldDisableAvatarLinkAndTooltip: true,
    });

    return part;
  },

  createAdminNotePart(id, blocks) {
    let admin = this.get('app.currentAdmin');
    let partOptions = {
      id: `comment-123-${id}`,
      admin_id: admin.id,
      created_at: new Date().toJSON(),
      type: 'comment',
      sub_type: 'note',
      admin_only: true,
      blocks,
    };

    let part = this._pushAndPeekPart(partOptions);
    part.set('shouldDisableAvatarLinkAndTooltip', true);
    return part;
  },

  createPreviewConversation(participant, parts) {
    let app = this.app;

    let conversation = this.store.createRecord('conversation', {
      id: '123',
      app_id: app.get('id'),
      assignee_id: '',
      app,
      lastActionPart: parts[2],
      participants: [participant],
      isOneToOneConversation: true,
      disableSeenStateUpdates: true,
      disableFetchParts: true,
      isClosed: false,
      isNotClosed: true,
    });
    conversation.addParts(parts);
    conversation.set('hasFetchedParts', true);

    return conversation;
  },

  _pushAndPeekPart(data) {
    this.store.pushPayload({
      'conversation-part': data,
    });

    return this.store.peekRecord('conversation-part', data.id);
  },
});
