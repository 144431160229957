/* import __COLOCATED_TEMPLATE__ from './new-account-modal.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import EmberObject, { action } from '@ember/object';
import isValidUrl from 'embercom/lib/url-validator';

export default Component.extend({
  intercomEventService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  store: service(),
  validAccountDomain: applyFunction(isValidUrl, 'model.domain'),
  errors: [],

  init() {
    this._super(...arguments);
    this.set(
      'model',
      EmberObject.create({
        ownerId: this.get('app.currentAdmin.id'),
      }),
    );
  },

  _trackCreate() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'created',
      object: 'account',
    });
  },

  createAccount: action(async function () {
    this.set('errors', []);
    let account;

    try {
      if (!this.validAccountDomain) {
        this.set('errors', [{ message: `${this.model.domain} is not a valid domain.` }]);
        return;
      }

      account = this.store.createRecord('account', this.model);
      await account.save();
      this._trackCreate();
      await this.closeModal();
    } catch (e) {
      if (e.jqXHR && e.jqXHR.responseJSON) {
        this.set('errors', e.jqXHR.responseJSON.errors);
      } else {
        this.set('errors', [{ message: 'Something went wrong. Please try again.' }]);
      }
      account.unloadRecord();
    }
  }),

  changeOwner: action(function (ownerId) {
    let account = this.model;
    account.set('ownerId', ownerId);
  }),
});
