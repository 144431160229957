/* import __COLOCATED_TEMPLATE__ from './your-settings-submenu.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { match, readOnly } from '@ember/object/computed';
import BaseSettingsSubmenu from 'embercom/components/base-settings-submenu';
import RouteRegexes from 'embercom/lib/route-regexes';

export default BaseSettingsSubmenu.extend({
  admin: readOnly('app.currentAdmin'),
  isOnYourAccountRoute: match('internalCurrentRouteName', RouteRegexes.accountSettings.yourAccount),
  isOnNotificationsRoute: match(
    'internalCurrentRouteName',
    RouteRegexes.accountSettings.notifications,
  ),
  isOnInboxSettingsRoute: match(
    'internalCurrentRouteName',
    RouteRegexes.accountSettings.inboxSettings,
  ),
  isOnVisibilityRoute: match('internalCurrentRouteName', RouteRegexes.accountSettings.visibility),
  isOnAuthenticationRoute: match(
    'internalCurrentRouteName',
    RouteRegexes.accountSettings.authentication,
  ),
  isOnAccountAccessRoute: match(
    'internalCurrentRouteName',
    RouteRegexes.accountSettings.accountAccess,
  ),
});
